/* eslint-disable no-bitwise */
import { without } from 'lodash'
import Generator from 'random-seed'
const seed = 'chess'
export const random = Generator.create(seed)

export const randomId = (length = 8) =>
  `rnd-${random.intBetween(10 ** length, 10 ** (length + 1) - 1)}`

export const randomPick = <T>(array: Array<T>) => {
  const idx = random.intBetween(0, array.length - 1)
  return array[idx]
}

export const randomPickSeeded = <T>(array: Array<T>, seed: string) => {
  const rng = Generator.create(seed)
  const idx = rng.intBetween(0, array.length - 1)
  return array[idx]
}

const uuidPattern = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'

export const uuid = (rng: () => number = Math.random) => {
  return uuidPattern.replace(/[xy]/g, function (c) {
    var r = (rng() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export const uuidify = (id: string) => {
  if (id.length === uuidPattern.length) return id
  const rng = Generator.create(id)
  return uuid(rng.random)
}

export interface GetRandomColorOptions {
  seed: string
  hue?: number
  saturation?: number
  brightness?: number
  opacity?: number
}

export const getRandomColor = ({
  seed,
  hue,
  saturation,
  brightness,
  opacity = 1,
}: GetRandomColorOptions) => {
  const rng = Generator.create(seed)
  hue = hue ?? rng.intBetween(0, 359)
  saturation = saturation ?? rng.intBetween(30, 70)
  brightness = brightness ?? rng.intBetween(40, 60)
  return `hsl(${hue} ${saturation}% ${brightness}% / ${opacity})`
}

export const randomShuffleSeeded = <T>(array: Array<T>, seed: string) => {
  if (!array) return []
  let notPicked = array
  let picked: Array<T> = []
  while (notPicked.length) {
    const pick = randomPickSeeded(notPicked, seed)
    picked = [...picked, pick]
    notPicked = without(notPicked, pick)
  }
  return picked
}
