import { FenContext, IFenContext } from '@choiss/react'
import React, { FunctionComponent } from 'react'
import { useFenUrl, useSetFenUrl } from './useFenUrl'

export const FenContextProvider: FunctionComponent = ({ children }) => {
  const fen = useFenUrl()
  const setFen = useSetFenUrl()
  const context: IFenContext = {
    fen,
    setFen,
  }
  return <FenContext.Provider value={context}>{children}</FenContext.Provider>
}
