import { useContext } from 'react'
import { FenContext } from './FenContext'

export const decodeFen = (fenEncoded?: string) => {
  const fen = fenEncoded ? decodeURIComponent(fenEncoded) : undefined
  return fen
}

export const encodeFen = (fen: string) => {
  return encodeURIComponent(fen)
}

export const useFenUrl = () => {
  const { fen } = useContext(FenContext)
  return fen
}

export const useSetFenUrl = () => {
  const { setFen } = useContext(FenContext)
  return setFen
}
