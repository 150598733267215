import { AnimatePresence, motion } from 'framer-motion'
import React, { FunctionComponent } from 'react'
import { enableMotionFramerLayoutId } from '../util/config'
import { getDragTarget, setDragTarget } from '../util/drag.util'
import { Field } from './Board'
import { BoardPieceIcon } from './BoardPieceIcon'
import { useDoMove } from './useDoMove'
import { useGetPieceOrigin } from './useGetPieceOrigin'
import { BoardPiece } from './usePieces'

export const BoardPieceDisplay: FunctionComponent<{
  boardPiece: BoardPiece
}> = ({ boardPiece }) => {
  const { fieldLabel, color } = boardPiece
  const origin = useGetPieceOrigin(fieldLabel)
  const doMove = useDoMove()

  const onDragEnd = () => {
    const from = fieldLabel
    const to = getDragTarget()
    doMove({
      from,
      to,
      promotion: 'q',
    } as any)
  }
  const onDragOver = () => setDragTarget(fieldLabel)
  const layoutProps = enableMotionFramerLayoutId
    ? { layoutId: `${origin}${color}` }
    : {}

  // useEffect(() => {
  //   console.log({ layoutId: layoutProps.layoutId, fieldLabel, color })
  // }, [color, fieldLabel, layoutProps.layoutId])
  return (
    <>
      <Field
        style={{ gridArea: fieldLabel, zIndex: 10 }}
        draggable={true}
        onDragEnd={onDragEnd}
        onDragOver={onDragOver}
      >
        <AnimatePresence>
          <motion.div
            {...layoutProps}
            style={{ width: '100%', height: '100%' }}
          >
            <BoardPieceIcon piece={boardPiece} />
            {/* {layoutProps.layoutId} */}
          </motion.div>
        </AnimatePresence>
      </Field>
    </>
  )
}
