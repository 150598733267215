import { AnimateSharedLayout } from 'framer-motion'
import { filter, map } from 'lodash'
import React, { FunctionComponent, useContext } from 'react'
import { ArcherContainer, ArcherElement } from 'react-archer'
import styled, { css } from 'styled-components'
import { boardFields } from '../util/board.util'
import { BoardField } from './BoardField'
import { BoardPieceDisplay } from './BoardPieceDisplay'
import { PieceOriginContext } from './PieceOriginContext'
import { SettingsContext } from './SettingsContext'
import { MoveChoice } from './useMoveChoices'
import { usePieces } from './usePieces'

const Grid = styled.div<{ flipped?: boolean }>`
  display: grid;
  grid-template-rows: repeat(8, var(--square-size));
  grid-template-columns: repeat(8, var(--square-size));

  ${({ flipped = false }) =>
    flipped
      ? css`
          grid-template-areas:
            'h1 g1 f1 e1 d1 c1 b1 a1'
            'h2 g2 f2 e2 d2 c2 b2 a2'
            'h3 g3 f3 e3 d3 c3 b3 a3'
            'h4 g4 f4 e4 d4 c4 b4 a4'
            'h5 g5 f5 e5 d5 c5 b5 a5'
            'h6 g6 f6 e6 d6 c6 b6 a6'
            'h7 g7 f7 e7 d7 c7 b7 a7'
            'h8 g8 f8 e8 d8 c8 b8 a8';
        `
      : css`
          grid-template-areas:
            'a8 b8 c8 d8 e8 f8 g8 h8'
            'a7 b7 c7 d7 e7 f7 g7 h7'
            'a6 b6 c6 d6 e6 f6 g6 h6'
            'a5 b5 c5 d5 e5 f5 g5 h5'
            'a4 b4 c4 d4 e4 f4 g4 h4'
            'a3 b3 c3 d3 e3 f3 g3 h3'
            'a2 b2 c2 d2 e2 f2 g2 h2'
            'a1 b1 c1 d1 e1 f1 g1 h1';
        `}

  padding: calc(var(--square-size) / 8);
  border-radius: calc(var(--square-size) / 8);
  background: #616161;
  /* box-shadow: calc(var(--square-size) / 2) calc(var(--square-size) / 2)
      var(--square-size) #525252,
    calc(var(--square-size) / -2) calc(var(--square-size) / -2)
      var(--square-size) #707070; */

  border: 2px solid transparent;
`

export const Field = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-bg);
`

export const Board: FunctionComponent<{
  highlightField?: string
  moves?: Pick<MoveChoice, 'from' | 'to' | 'color'>[]
  color?: string
}> = ({ highlightField, moves, color }) => {
  const pieces = usePieces()
  const { origins } = useContext(PieceOriginContext)
  const { flipped, setFlipped } = useContext(SettingsContext)
  return (
    <>
      <ArcherContainer>
        <AnimateSharedLayout>
          <Grid
            style={
              color
                ? {
                    borderColor: color,
                  }
                : {}
            }
            flipped={flipped}
            onDoubleClick={() => setFlipped(!flipped)}
          >
            {boardFields.map((fieldLabel) => {
              const movesFromHere = filter(
                moves,
                (move) => move.from === fieldLabel
              )
              const relations = map(
                movesFromHere,
                (move) =>
                  ({
                    targetId: move.to,
                    targetAnchor: 'middle',
                    sourceAnchor: 'middle',
                    style: {
                      strokeColor: move.color,
                      strokeWidth: 4,
                      endShape: {
                        // arrow: { arrowLength: 4, arrowThickness: 4 },
                        circle: {
                          radius: 1.5,
                          strokeWidth: 0.01,
                          fillColor: move.color,
                          strokeColor: move.color,
                        },
                      },
                    },
                  } as const)
              )
              return (
                <ArcherElement
                  id={fieldLabel}
                  key={fieldLabel}
                  relations={relations}
                >
                  <div style={{ gridArea: fieldLabel }}>
                    <BoardField key={fieldLabel} fieldLabel={fieldLabel} />
                  </div>
                </ArcherElement>
              )
            })}
            {!!highlightField && (
              <Field
                style={{
                  gridArea: highlightField,
                  background: 'var(--color-square-highlight)',
                }}
              />
            )}
            {pieces?.map((piece) => {
              const origin = origins[piece.fieldLabel]
              return <BoardPieceDisplay key={origin} boardPiece={piece} />
            })}
          </Grid>
        </AnimateSharedLayout>
      </ArcherContainer>
    </>
  )
}
