/* eslint-disable react/jsx-no-useless-fragment */
import React, { FunctionComponent } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { persistWithLocalStorage } from 'react-query/persist-localstorage-experimental'
import { SettingsContextProvider } from './SettingsContext'
import { SwarmContextProvider } from './SwarmContext'

const queryClient = new QueryClient()
persistWithLocalStorage(queryClient)

export const MainProvider: FunctionComponent<{}> = ({ children }) => {
  return (
    <SettingsContextProvider>
      <SwarmContextProvider>
        <QueryClientProvider client={queryClient}>
          <>{children}</>
        </QueryClientProvider>
      </SwarmContextProvider>
    </SettingsContextProvider>
  )
}
