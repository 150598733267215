import React from 'react'
import styled from 'styled-components'
import { BoardWithButtons } from './BoardWithButtons'
import { ChessGameContextProvider } from './ChessGameContext'
import { PieceOriginContextProvider } from './PieceOriginContext'
import { useFenUrl } from './useFenUrl'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  @media (max-width: 1200px) {
    --square-size: 32px;
  }
  --main-gap: calc(var(--square-size) / 4);

  gap: calc(1 * var(--main-gap));
  padding: calc(1 * var(--main-gap));
`

const Content = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 1200px) {
    flex-direction: column;
  }
  gap: var(--main-gap);
`

const PlainLink = styled.a`
  color: inherit;
  text-decoration: none;
`

const Title = styled.h1`
  margin: 0;
`

const Footer = styled.div``

export const Game = () => {
  const fen = useFenUrl()
  return (
    <>
      <Container>
        <PlainLink href="/">
          <Title>choiss.app</Title>
        </PlainLink>
        <Content>
          <PieceOriginContextProvider>
            <ChessGameContextProvider options={{ fen }}>
              <BoardWithButtons />
            </ChessGameContextProvider>
          </PieceOriginContextProvider>
        </Content>
        <Footer>
          <PlainLink href="https://rechenberger.io/" target="_blank">
            by Tristan Rechenberger
          </PlainLink>
        </Footer>
      </Container>
    </>
  )
}
