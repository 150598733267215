import React, { FunctionComponent, useContext, useMemo } from 'react'
import { SHOW_MORE_BUTTONS } from '../util/config'
import { Board } from './Board'
import { MoreButtons } from './MoreButtons'
import { MoveButtons } from './MoveButtons'
import { PieceOriginContext } from './PieceOriginContext'
import { useChessGame } from './useChessGame'
import { useMoveChoices } from './useMoveChoices'

export const BoardWithButtons: FunctionComponent<{}> = () => {
  const { moves, loading } = useMoveChoices()
  const { lastMove } = useContext(PieceOriginContext)
  const chessGame = useChessGame()
  const showLastMove = false
  const allMoves = useMemo(
    () =>
      showLastMove
        ? [...moves, { ...lastMove, color: 'rgba(255,255,255,0.54)' } as any]
        : moves,
    [showLastMove, moves, lastMove]
  )
  if(!chessGame) return null
  const color = chessGame.turn() === 'w' ? 'white' : 'black'
  return (
    <>
      <Board moves={allMoves} color={color} />
      <MoveButtons moves={moves} loading={loading} />
      {SHOW_MORE_BUTTONS && <MoreButtons moves={moves} loading={loading} />}
    </>
  )
}
