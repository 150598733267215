import { Move } from 'chess.js'
import { keyBy } from 'lodash'
import React, {
  createContext,
  FunctionComponent,
  useCallback,
  useState,
} from 'react'
import { boardFields } from '../util/board.util'

const defaultOrigins = keyBy(boardFields, (field) => field)

const usePieceOriginContextState = () => {
  const [origins, setOrigins] = useState(defaultOrigins)
  const [lastMove, setLastMove] = useState<Move | null>(null)

  const handleMove = useCallback((move: Move) => {
    setLastMove(move)
    setOrigins((origins) => {
      const { from, to } = move
      const origin = origins[from]
      return {
        ...origins,
        [to]: origin,
      }
    })
  }, [])

  return {
    origins,
    lastMove,
    handleMove,
  }
}

type IPieceOriginContext = ReturnType<typeof usePieceOriginContextState>

export const PieceOriginContext = createContext({} as IPieceOriginContext)

export const PieceOriginContextProvider: FunctionComponent = ({ children }) => {
  const context = usePieceOriginContextState()
  return (
    <PieceOriginContext.Provider value={context}>
      {children}
    </PieceOriginContext.Provider>
  )
}
