import { ChessInstance } from 'chess.js'
import { flatMap } from 'lodash'
import { getLabelByIdx } from '../util/board.util'
import { useChessGame } from './useChessGame'

export const usePieces = () => {
  const chessGame = useChessGame()
  if (!chessGame) return null
  const board = chessGame.board()
  if (!board) return null
  const pieces = boardToPieces(board)
  return pieces
}

export type Board = ReturnType<ChessInstance['board']>
export type BoardPiece = ReturnType<typeof boardToPieces>[number]

const boardToPieces = (board: Board) => {
  const pieces = flatMap(board, (row, rowIdx) => {
    rowIdx = 7 - rowIdx
    return flatMap(row, (field, colIdx) => {
      if (!field) return []
      const fieldLabel = getLabelByIdx({
        colIdx,
        rowIdx,
      })
      return [
        {
          fieldLabel,
          ...field,
        },
      ]
    })
  })
  return pieces
}
