import { ShortMove } from 'chess.js'
import { useCallback, useContext } from 'react'
import { cloneGame } from '../util/chess.util'
import { ChessGameContext } from './ChessGameContext'
import { PieceOriginContext } from './PieceOriginContext'
import { useSetFenUrl } from './useFenUrl'

export const useDoMove = () => {
  const { chessGame, setChessGame } = useContext(ChessGameContext)
  const { handleMove } = useContext(PieceOriginContext)
  const setFenUrl = useSetFenUrl()
  return useCallback(
    (move: string | ShortMove) => {
      const game = cloneGame(chessGame)
      const fenBefore = game.fen()
      const m = game.move(move)
      if (!m) {
        console.warn('Invalid move:', move)
        return
      }
      handleMove(m)
      const fen = game.fen()
      if (fen === fenBefore) return
      setFenUrl(fen)
      setChessGame(game)
    },
    [chessGame, handleMove, setChessGame, setFenUrl]
  )
}
