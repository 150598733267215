import { StockfishWorker } from './stockfishWorker'
import { IStockfishSwarm } from './swarm/IStockfishSwarm'

export interface StockfishDoOptions {
  startMessages: string[]
  endMessage: string
  swarm?: IStockfishSwarm
}

export interface StockfishWorkerDoOptions extends StockfishDoOptions {
  worker: StockfishWorker
}

export const stockfishWorkerDo = (options: StockfishWorkerDoOptions) => {
  return new Promise<string[]>((res, rej) => {
    try {
      const { worker, endMessage, startMessages } = options
      const messages: string[] = []
      worker.onmessage = (messageRaw) => {
        const message =
          typeof messageRaw === 'string' ? messageRaw : messageRaw.data

        console.debug('Stockfish:', message)
        messages.push(message)
        if (message.startsWith(endMessage)) {
          res(messages)
        }
      }
      worker.onerror = (error) => {
        console.error(error)
        worker.terminate()
        rej(error)
      }
      startMessages.forEach((message) => {
        console.debug('StockfishDo:', message)
        worker.postMessage(message)
      })
    } catch (error) {
      console.log('error')
    }
  })
}
