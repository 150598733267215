import { calcScoreByFen } from '@choiss/stockfish'
import { first, map } from 'lodash'
import { useQuery } from 'react-query'
import { scoreByFen } from '../util/config'
import { MoveWithFen, useMoves } from './useMoves'
import { useSwarm } from './useSwarm'

export type MoveWithScore = MoveWithFen & { score: number }

export const useMovesWithScores = () => {
  const moves = useMoves()
  const swarm = useSwarm()
  const fenBefore = first(moves)?.fenBefore

  const r = useQuery(
    ['movesWithScore', fenBefore],
    async () => {
      return promiseAllMap({
        array: moves,
        mapFunc: async (move) => {
          if (!swarm) return undefined
          const score = await getScoreForMove(move, swarm)
          return {
            ...move,
            score,
          } as MoveWithScore
        },
      })
    },
    {
      enabled: !!swarm,
      staleTime: Infinity,
    }
  )

  return { loading: r.isLoading, moves: r.data }
}

const getScoreForMove = async (move: MoveWithFen, swarm: any) => {
  if (move.san.endsWith('#')) {
    return Infinity
  }
  if (scoreByFen) {
    // getScoreByFen calculates score for the active player in the current position,
    // so we have to inverse it to reflect the score from the perspective of the move
    const scoreForOther = await calcScoreByFen(move.fen, swarm)
    const score = -1 * scoreForOther
    return score
  } else {
    // FIXME: This format does not always work, stockfish needs a specific notation
    // const moveString = `${move.from}${move.to}`
    // const score = await calcScoreByMove(move.fenBefore, moveString)
    // return score
    throw new Error(`calcScoreByMove deprecated`)
  }
}

const promiseAllMap = async <T, U>({
  array,
  mapFunc,
  parallel = true,
}: {
  array: T[]
  mapFunc: (entity: T) => Promise<U>
  parallel?: boolean
}) => {
  if (parallel) {
    return Promise.all(map(array, mapFunc))
  } else {
    const results: U[] = []
    for (const element of array) {
      const result = await mapFunc(element)
      results.push(result)
    }
    return results
  }
}
