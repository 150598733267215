import { filter, last } from 'lodash'
import { depth } from '../config'
import { infoMessageToScore } from './formatScore'
import { stockfishDo } from './stockfishDo'
import { IStockfishSwarm } from './swarm/IStockfishSwarm'

export const calcScoreByFen = async (fen: string, swarm: IStockfishSwarm) => {
  const messages = await stockfishDo({
    startMessages: [`position fen ${fen}`, `go depth ${depth}`],
    endMessage: `bestmove`,
    swarm,
  })
  const infoMessages = filter(messages, (message) => message.startsWith('info'))
  const lastInfoMessage = last(infoMessages)
  const score = infoMessageToScore(lastInfoMessage)
  return score
}

// let lastFen = ''
// export const calcScoreByMove = async (fen: string, move: string) => {
//   let startMessages = [`go depth ${depth} searchmoves ${move}`]
//   if (lastFen !== fen) {
//     startMessages = [`position fen ${fen}`, ...startMessages]
//   }
//   // lastFen = fen
//   const messages = await stockfishDo({
//     startMessages,
//     endMessage: `bestmove`,
//   })
//   const infoMessages = filter(messages, (message) => message.startsWith('info'))
//   const lastInfoMessage = last(infoMessages)
//   const score = infoMessageToScore(lastInfoMessage)
//   return score
// }
