import { map } from 'lodash'
import { NUMBER_OF_WORKERS } from '../../config'
import { hasGlobalStockfish, newStockfishWorker } from '../stockfishWorker'
import { StockfishDoOptions, stockfishWorkerDo } from '../stockfishWorkerDo'
import { IStockfishSwarm } from './IStockfishSwarm'

const emptyArray = (size: number) => {
  return new Array(size).fill(null)
}

export class StockfishSwarmWorker implements IStockfishSwarm {
  private numberOfWorkers = hasGlobalStockfish ? 1 : NUMBER_OF_WORKERS

  private workers = map(emptyArray(this.numberOfWorkers), () =>
    newStockfishWorker()
  )
  private promises = map(emptyArray(this.numberOfWorkers), () =>
    Promise.resolve<string[]>([])
  )
  private nextIdx = 0

  private increaseNextIdx() {
    this.nextIdx = (this.nextIdx + 1) % this.numberOfWorkers
  }

  async do(options: StockfishDoOptions): Promise<string[]> {
    const worker = this.workers[this.nextIdx]
    const nextPromise = this.promises[this.nextIdx]

    const newPromise = nextPromise.then(() =>
      stockfishWorkerDo({
        ...options,
        worker,
      })
    )

    this.promises[this.nextIdx] = newPromise
    this.increaseNextIdx()

    return newPromise.catch(() => this.do(options))
  }
}
