import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { Loader } from './Loader'
import { MoveButton } from './MoveButton'
import { MoveChoice } from './useMoveChoices'

const Grid = styled.div`
  display: grid;
  gap: var(--main-gap);
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  align-items: center;
  justify-items: center;
  /* width: 528px;
  height: 528px; */
  width: calc(var(--square-size) * 8 + 16px);
  height: calc(var(--square-size) * 8 + 16px);
`

export const MoveButtons: FunctionComponent<{
  moves: MoveChoice[]
  loading: boolean
}> = ({ moves, loading }) => {
  return (
    <>
      <Grid>
        {loading ? (
          <div style={{ gridColumn: 'span 2', gridRow: 'span 2' }}>
            <Loader />
          </div>
        ) : (
          moves.map((move) => <MoveButton key={move.san} move={move} />)
        )}
      </Grid>
    </>
  )
}
