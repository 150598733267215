import { StockfishDoOptions, stockfishWorkerDo } from '../stockfishWorkerDo'
import { IStockfishSwarm } from './IStockfishSwarm'

export class StockfishSwarmNode implements IStockfishSwarm {
  private worker: any

  async getWorker() {
    if (!this.worker) {
      // const Stockfish = await import('stockfish')
      // this.worker = Stockfish()
      this.worker = globalThis.stockfish
    }
    return this.worker
  }

  async do(options: StockfishDoOptions): Promise<string[]> {
    const worker = await this.getWorker()
    return stockfishWorkerDo({
      ...options,
      worker,
    })
  }
}
