import { flatMap, indexOf, map } from 'lodash'

const columnLabels = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h']
const rowLabels = new Array(8).fill(null).map((_, rowIdx) => rowIdx + 1)

export const boardFields = flatMap(columnLabels, (columnLabel) =>
  map(rowLabels, (rowLabel) => `${columnLabel}${rowLabel}`)
)

export const isLightField = (fieldLabel: string) => {
  const { rowIdx, colIdx } = getIdxByLabel(fieldLabel)
  const a = rowIdx % 2 === 0
  const b = colIdx % 2 === 0
  return a !== b
}

const getIdxByLabel = (fieldLabel: string) => {
  const fieldIdx = indexOf(boardFields, fieldLabel)
  const rowIdx = fieldIdx % 8
  const colIdx = Math.floor(fieldIdx / 8)
  return { fieldIdx, rowIdx, colIdx }
}

export const getLabelByIdx = ({
  colIdx,
  rowIdx,
}: {
  colIdx: number
  rowIdx: number
}) => {
  const rowLabel = rowLabels[rowIdx]
  const columnLabel = columnLabels[colIdx]
  return `${columnLabel}${rowLabel}`
}
