import { indexOf } from 'lodash'

const MATE_SCORE = 10 ** 6

export const infoMessageToScore = (msg?: string) => {
  if (!msg) return 0
  const parts = msg?.split(' ')
  const idx = indexOf(parts, 'score')
  const type = parts[idx + 1] as 'cp' | 'mate'
  const scoreString = parts[idx + 2]
  let score = Number(scoreString)
  if (type === 'mate') {
    const isPositive = score > 0
    const factor = isPositive ? +1 : -1
    const abs = Math.abs(score)
    score = factor * (MATE_SCORE - abs)
  }
  return score
}

export const formatScore = (score: number) => {
  const abs = Math.abs(score)
  if (abs === Infinity) return 'Mate'
  const isPositive = score > 0
  const prefix = isPositive ? '+' : '-'
  const isMate = abs >= MATE_SCORE / 10
  if (isMate) {
    const mateIn = MATE_SCORE - abs
    return `${prefix}#${mateIn}`
  } else {
    const pawns = (abs / 100).toFixed(2)
    return `${prefix}${pawns}`
  }
}
