import { IStockfishSwarm, StockfishSwarmWorker } from '@choiss/stockfish'
import React, {
  createContext,
  FunctionComponent,
  useEffect,
  useState,
} from 'react'

const useSwarmContextState = () => {
  const [swarm, setSwarm] = useState<IStockfishSwarm>(null)
  useEffect(() => {
    if (!window) return
    setSwarm(new StockfishSwarmWorker())
  }, [])
  return {
    swarm,
  }
}

type ISwarmContext = ReturnType<typeof useSwarmContextState>

export const SwarmContext = createContext({} as ISwarmContext)

export const SwarmContextProvider: FunctionComponent = ({ children }) => {
  const context = useSwarmContextState()
  return (
    <SwarmContext.Provider value={context}>{children}</SwarmContext.Provider>
  )
}
