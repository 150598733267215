export type StockfishWorker = Worker

const globalStockfish = globalThis.stockfish
export const hasGlobalStockfish = !!globalStockfish

export const newStockfishWorker = () => {
  if (hasGlobalStockfish) {
    return globalThis.stockfish
  }
  const worker = new Worker('/assets/stockfish/stockfish.js')
  return worker
}
