import { Game, Testarea } from '@choiss/react'
import React, { FunctionComponent } from 'react'
import { Route, Switch } from 'react-router-dom'
import { FenContextProvider } from './FenContextRouter'

export const Routes: FunctionComponent<{}> = () => {
  return (
    <Switch>
      <Route exact path="/">
        <FenContextProvider>
          <Game />
        </FenContextProvider>
      </Route>
      <Route path="/fen/:fen">
        <FenContextProvider>
          <Game />
        </FenContextProvider>
      </Route>
      <Route path="/test">
        <Testarea />
      </Route>
    </Switch>
  )
}
