import { formatScore } from '@choiss/stockfish'
import { first, orderBy } from 'lodash'
import React, { FunctionComponent, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { showScore } from '../util/config'
import { randomPickSeeded } from '../util/random.util'
import { useChessGame } from './useChessGame'
import { useDoMove } from './useDoMove'
import { MoveChoice } from './useMoveChoices'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 32px;
`

const Button = styled.button`
  width: 32px;
  height: 32px;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  font-size: 24px;
`

export const MoreButtons: FunctionComponent<{
  moves: MoveChoice[]
  loading: boolean
}> = ({ moves, loading }) => {
  const doMove = useDoMove()
  const chessGame = useChessGame()
  const bestMove = first(orderBy(moves, (move) => move.score, 'desc'))
  const randomMove = randomPickSeeded(moves, chessGame.fen())

  const scoreLabel = useMemo(() => {
    const scoreFactor = chessGame.turn() === 'w' ? +1 : -1
    const score = (bestMove?.score || 0) * scoreFactor
    const scoreLabel = formatScore(score)
    return scoreLabel
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bestMove?.score])

  useEffect(() => {
    if (loading) return
    console.log(`Current Score (best move):`, scoreLabel)
  }, [scoreLabel, loading])

  return (
    <>
      <Container>
        <Button
          disabled={loading}
          onClick={() => {
            randomMove && doMove(randomMove.san)
          }}
        >
          🔀
        </Button>
        <Button
          disabled={loading}
          onClick={() => {
            bestMove && doMove(bestMove.san)
          }}
        >
          👑
        </Button>
        {showScore && (
          <Button disabled={true} style={{ fontSize: 12, fontWeight: 'bold' }}>
            {scoreLabel}
          </Button>
        )}
      </Container>
    </>
  )
}
