import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { Board } from './Board'
import { ChessGameContextProvider } from './ChessGameContext'
import { useDoMove } from './useDoMove'
import { MoveChoice } from './useMoveChoices'

const StyledButton = styled.button`
  --square-size: 30px;
  @media (max-width: 1200px) {
    --square-size: 15px;
  }
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  width: min-content;
`

export const MoveButton: FunctionComponent<{ move: MoveChoice }> = ({
  move,
}) => {
  const { san, fen } = move
  const doMove = useDoMove()
  return (
    <StyledButton onClick={() => doMove(san)}>
      <ChessGameContextProvider options={{ fen }}>
        <Board
          // highlightField={to}
          moves={[move]}
          color={move.color}
        />
      </ChessGameContextProvider>
    </StyledButton>
  )
}
