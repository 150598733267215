import { MainProvider } from '@choiss/react'
import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import './app.css'
import { Routes } from './components/Routes'

function App() {
  return (
    <BrowserRouter>
      <MainProvider>
        <Routes />
      </MainProvider>
    </BrowserRouter>
  )
}

export default App
