import React, {
  createContext,
  FunctionComponent,
  useEffect,
  useState,
} from 'react'
import { newChessGame } from '../util/chess.util'

interface ChessGameContextOptions {
  fen?: string
}

const useChessGameContextState = ({ fen }: ChessGameContextOptions) => {
  const [chessGame, setChessGame] = useState(newChessGame(fen))
  return {
    chessGame,
    setChessGame,
  }
}

type IChessGameContext = ReturnType<typeof useChessGameContextState>

export const ChessGameContext = createContext({} as IChessGameContext)

export const ChessGameContextProvider: FunctionComponent<{
  options?: ChessGameContextOptions
}> = ({ children, options = {} }) => {
  const context = useChessGameContextState(options)

  // Update ChessGame, when fen changes
  const { fen } = options
  const { setChessGame, chessGame } = context
  useEffect(() => {
    const currentFen = chessGame.fen()
    const newGame = newChessGame(fen)
    const newFen = newGame.fen()
    if (newFen !== currentFen) {
      setChessGame(newGame)
    }
  }, [chessGame, fen, setChessGame])

  return (
    <ChessGameContext.Provider value={context}>
      {children}
    </ChessGameContext.Provider>
  )
}
