import { filter, orderBy, reverse, take, takeRight, without } from 'lodash'
import { useContext, useMemo } from 'react'
import { colorize } from '../util/colorize.util'
import { movesSelectBest, movesSelectWorst } from '../util/config'
import { SettingsContext } from './SettingsContext'
import { MoveWithScore, useMovesWithScores } from './useMovesWithScores'

export type MoveChoice = MoveWithScore & { color: string }

export const useMoveChoices = () => {
  const { moves: movesWithScores, loading } = useMovesWithScores()
  const { flipped } = useContext(SettingsContext)
  // const chessGame = useChessGame()
  const moves = useMemo(() => {
    // TODO: Find a more interesting way to select choices
    console.debug('movesWithScores:', movesWithScores)
    let moves = movesWithScores
    moves = orderBy(moves, (move) => move.score, 'desc')

    const best = take(moves, movesSelectBest)
    moves = without(moves, ...best)
    const worstTakes = takeRight(
      filter(moves, (move) => move.san.includes('x')),
      movesSelectWorst
    )
    moves = without(moves, ...worstTakes)
    const worst = takeRight(moves, movesSelectWorst - worstTakes.length)
    moves = without(moves, ...worst)
    moves = [...best, ...worstTakes, ...worst]

    moves = orderBy(moves, ({ from, to }) => `${from}${to}`, 'asc')
    let movesWithColors: MoveChoice[] = colorize(moves)
    if (flipped) {
      movesWithColors = reverse(movesWithColors)
    }
    console.debug('moveChoices:', movesWithColors)
    return movesWithColors
  }, [flipped, movesWithScores])

  return { moves, loading }
}
