import Chess from 'chess.js'

type ChessGame = Chess.ChessInstance

const getChess = () => {
  let C = Chess as any
  if (!C) {
    throw new Error(`chess.js not found`)
  }
  if (typeof C !== 'function') {
    C = C.Chess
  }
  return C as (fen?: string) => ChessGame
}

export const newChessGame = (fen?: string) => {
  const Chess = getChess()
  const game = Chess(fen)
  return game as ChessGame
}

export const cloneGame = (game: ChessGame) => {
  return newChessGame(game.fen())
}
