import { Move } from 'chess.js'
import { map, orderBy } from 'lodash'
import { useMemo } from 'react'
import { cloneGame } from '../util/chess.util'
import { useChessGame } from './useChessGame'

export type MoveWithFen = Move & {
  fen: string
  fenBefore: string
}

export const useMoves = () => {
  const game = useChessGame()
  return useMemo(() => {
    let moves = map(game?.moves(), (move) => {
      const gameWithMove = cloneGame(game)
      const fenBefore = gameWithMove.fen()
      const m = gameWithMove.move(move) as Move
      const fen = gameWithMove.fen()
      return {
        ...m,
        fen,
        fenBefore,
      } as MoveWithFen
    })

    // Make order of moves deterministic:
    moves = orderBy(moves, ({ from, to }) => `${from}${to}`, 'asc')

    return moves
  }, [game])
}
