import { map } from 'lodash'

const colors = ['#F44336', '#2196F3', '#FFEB3B', '#4CAF50']

export const colorize = <T>(array: Array<T>) => {
  return map(array, (element, idx) => {
    let color = colors[idx]
    // color = `${color}aa`
    return {
      ...element,
      color,
    }
  })
}
