import { decodeFen, encodeFen } from '@choiss/react'
import { useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'

export const useFenUrl = () => {
  const params = useParams<{ fen?: string }>()
  console.log({ params })
  const { fen: fenEncoded } = useParams<{ fen?: string }>()
  const fen = decodeFen(fenEncoded)
  console.debug('fen', fen)
  return fen
}

export const useSetFenUrl = () => {
  const history = useHistory()
  return useCallback(
    (fen: string) => {
      const fenEncoded = encodeFen(fen)
      history.push(`/fen/${fenEncoded}`)
    },
    [history]
  )
}
