import React, { createContext, FunctionComponent } from 'react'
import { useLocalStorageState } from './useLocalStorageState'

const useSettingsContextState = () => {
  const [flipped, setFlipped] = useLocalStorageState('settings-flipped', false)
  return {
    flipped,
    setFlipped,
  }
}

type ISettingsContext = ReturnType<typeof useSettingsContextState>

export const SettingsContext = createContext({} as ISettingsContext)

export const SettingsContextProvider: FunctionComponent = ({ children }) => {
  const context = useSettingsContextState()
  return (
    <SettingsContext.Provider value={context}>
      {children}
    </SettingsContext.Provider>
  )
}
