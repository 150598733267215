import React from 'react'
import { BoardPiece } from './usePieces'

export const BoardPieceIcon = ({
  piece,
  size = '100%',
}: {
  piece: Pick<BoardPiece, 'type' | 'color'>
  size?: number | string
}) => {
  const { type, color } = piece
  const url = `/assets/icons/chess/Chess_${type}${
    color === 'w' ? 'l' : 'd'
  }t45.svg`
  return <img src={url} alt="chess piece" style={{ height: size }} />
}
