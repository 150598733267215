import React, { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'
import { isLightField } from '../util/board.util'
import { setDragTarget } from '../util/drag.util'

const FieldStyles = styled.div<{ isLight: boolean }>`
  width: 100%;
  height: 100%;
  ${({ isLight }) =>
    isLight
      ? css`
          border-radius: calc(var(--square-size) / 8);
          background: #e0e0e0;
          box-shadow: inset calc(var(--square-size) / 64 * 6)
              calc(var(--square-size) / 64 * 6)
              calc(var(--square-size) / 64 * 13) #bebebe,
            inset calc(var(--square-size) / 64 * -6)
              calc(var(--square-size) / 64 * -6)
              calc(var(--square-size) / 64 * 13) #ffffff;
        `
      : ``}
`

export const BoardField: FunctionComponent<{ fieldLabel: string }> = ({
  fieldLabel,
}) => {
  const isLight = isLightField(fieldLabel)
  const onDragOver = () => setDragTarget(fieldLabel)
  return (
    <FieldStyles
      style={{ gridArea: fieldLabel, zIndex: 12 }}
      isLight={isLight}
      onDragOver={onDragOver}
    />
  )
}
