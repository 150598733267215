const state = {
  target: '',
}

export const setDragTarget = (target: string) => {
  state.target = target
}

export const getDragTarget = () => {
  return state.target
}
